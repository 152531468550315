/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import * as VueGoogleMaps from 'gmap-vue';
import ServiceContainer from '@flashpointbv/solar-service-container';
import Environment from '@flashpointbv/solar-ui-environment';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
import Tooltip from 'vue-directive-tooltip';

import BlazeSlider from '@flashpointbv/blaze-slider/dist/blaze-slider.esm';
import {UICoreBlazeSlider, UICoreSticky, UICoreOffCanvas} from '@flashpointbv/solar-ui-core';

import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);
new UICoreBlazeSlider();
window.BlazeSlider = BlazeSlider;

Vue.use(Tooltip);
Vue.use(VueI18n);
Vue.use(VueLazyload);
Vue.use(Vuex);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCv-_bN3jVh6R0MKjtcPAOEqV8Wuk3HP4M',
    libraries: 'places',
    region: 'NL',
    language: 'nl'
  }
});

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substr(0, 2).toLowerCase());
Environment().set('checkout_default_country', window.Locale.substr(3, 2));
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {CatalogProduct, CatalogBundleProduct, CatalogGroupedProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog/src';
import CustomerWishlist from '../../../../../vendor/flashpoint/solar-wishlist/resources/resources/assets/js/stores/wishlist';
import GlobalMessages from './stores/messages';
import ProductQuantity from './stores/catalog/product/quantity';
import CatalogCategory from './stores/views/catalog/category';
import CatalogPageBuilder from './stores/page-builder';

const store = new Vuex.Store({
  strict: false,
});

store.registerModule('CatalogCategory', CatalogCategory);
store.registerModule('CatalogPageBuilder', CatalogPageBuilder);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);
store.registerModule('ProductQuantity', ProductQuantity);

/* CHECKOUT */
store.registerModule('CheckoutQuote', CheckoutQuote);
store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutTotals', CheckoutTotals);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutAddress', CheckoutAddress);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutDatalayer', CheckoutDatalayer);

/* POSTNL PICKUPPOINTS */
store.registerModule('CheckoutPostNLTimeframes', CheckoutPostNLTimeframes);
store.registerModule('CheckoutPostNLPickupPoints', CheckoutPostNLPickupPoints);
store.registerModule('CheckoutShippingPickupPoints', CheckoutShippingPickupPoints);

store.registerModule('CustomerAddress', CustomerAddress);

store.registerModule('CustomerWishlist', CustomerWishlist);
store.registerModule('GlobalMessages', GlobalMessages);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
  {key: 'header-wishlist', concrete: require('./components/HeaderWishlist').default},
  {key: 'header-cart', concrete: require('./components/HeaderCart').default},
  {key: 'search-form', concrete: require('./components/search/SearchForm.vue').default},
  {key: 'catalog-product-upsell-off-canvas-item', concrete: () => import('./components/catalog/product/ProductUpsellOffCanvasItem.vue')},
  {key: 'ui-label', concrete: () => import('./components/UI/Label.vue')},

  {key: 'search-result-suggested-categories', concrete: () => import('./components/search/results/SearchResultSuggestedCategories.vue')},
  {key: 'search-result-suggested-products', concrete: () => import('./components/search/results/SearchResultSuggestedProducts.vue')},
  {key: 'search-result-suggested-keywords', concrete: () => import('./components/search/results/SearchResultSuggestedKeywords.vue')},

  {key: 'newsletter-subscribe', concrete: () => import('./components/NewsletterSubscribe.vue')},
  {key: 'newsletter-block-widget', concrete: () => import('./components/NewsletterBlockWidget.vue')},
  {key: 'store-locator-widget', concrete: () => import('./components/StoreLocatorWidget.vue')},
  {key: 'global-messages', concrete: () => import('./components/GlobalMessages.vue')},
  {key: 'global-navigation', concrete: () => import('./components/GlobalNavigation.vue')},
  {key: 'global-dialog', concrete: () => import('./components/GlobalDialog.vue')},

  //Blocks
  {key: 'block-image-slider', concrete: () => import('./components/blocks/ImageSlider.vue')},
  {key: 'block-product-row', concrete: () => import('./components/blocks/ProductRow.vue')},
  {key: 'block-blog-posts', concrete: () => import('./components/blocks/BlogPosts.vue')},
  {key: 'block-category-blocks', concrete: () => import('./components/blocks/CategoryBlocks.vue')},
  {key: 'block-hotspot', concrete: () => import('./components/blocks/Hotspot.vue')},
  {key: 'pill-slider', concrete: () => import('./components/blocks/PillSlider.vue')},

  //Catalog
  {key: 'catalog-category-view', concrete: () => import('./views/catalog/CatalogCategoryView.vue')},
  {key: 'catalog-page-builder-view', concrete: () => import('./views/catalog/CatalogPageBuilderView.vue')},
  {key: 'catalog-search-view', concrete: () => import('./views/catalog/CatalogSearchView.vue')},

  {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
  {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
  {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/toolbar/ProductListToolbar.vue')},
  {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('./components/catalog/toolbar/ProductListToolbarPaginator.vue')},
  {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSortSelect.vue')},
  {key: 'catalog-product-list-toolbar-size-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSizeSelect.vue')},
  {key: 'catalog-product-list-toolbar-active-filters', concrete: () => import('./components/catalog/toolbar/ProductListToolbarActiveFilters.vue')},
  {key: 'catalog-product-list-banner', concrete: () => import('./components/catalog/ProductListBanner.vue')},
  {key: 'catalog-product-shop-by-look-slider', concrete: () => import('./components/catalog/product/ProductShopByLookSlider.vue')},

  {key: 'catalog-product-add-to-cart', concrete: () => import('./components/catalog/product/ProductAddToCart.vue')},
  {key: 'catalog-product-bulk-add-to-cart', concrete: () => import('./components/catalog/product/ProductBulkAddToCart.vue')},

  {key: 'catalog-product-subcategory-list', concrete: () => import('./components/catalog/ProductSubCategoryList.vue')},
  {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue')},
  {key: 'catalog-product-list-attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
  {key: 'catalog-product-list-swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
  {key: 'catalog-product-list-price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},

  {key: 'catalog-product-configuration', concrete: () => import('./components/catalog/product/ProductConfiguration.vue')},
  {key: 'catalog-product-quantity', concrete: () => import('./components/catalog/product/ProductQuantity.vue')},
  {key: 'catalog-product-wishlist', concrete: () => import('./components/catalog/product/ProductWishlist.vue')},
  {key: 'catalog-product-stock-check', concrete: () => import('./components/catalog/product/ProductStockCheck.vue')},
  {key: 'catalog-product-sticky-add-to-cart', concrete: () => import('./components/catalog/product/StickyAddToCart.vue')},
  {key: 'catalog-product-out-of-stock-notification', concrete: () => import('./components/catalog/product/ProductOutOfStockNotification.vue')},

  {key: 'catalog-product-reviews', concrete: () => import('./components/catalog/product/ProductReviews.vue')},
  {key: 'catalog-product-stock-message', concrete: () => import('./components/catalog/product/ProductStockMessage.vue')},
  {key: 'catalog-product-review-form', concrete: () => import('./components/catalog/product/ProductReviewForm.vue')},

  {key: 'catalog-product-simple', concrete: () => import('./components/catalog/product/simple/ProductTypeSimple.vue')},
  {key: 'catalog-product-configurable', concrete: () => import('./components/catalog/product/configurable/ProductTypeConfigurable.vue')},
  {key: 'catalog-product-configurable-select', concrete: () => import('./components/catalog/product/configurable/type/OptionSelect.vue')},
  {key: 'catalog-product-configurable-swatch', concrete: () => import('./components/catalog/product/configurable/type/OptionSwatch.vue')},
  {key: 'catalog-product-bundle', concrete: () => import('./components/catalog/product/bundle/ProductTypeBundle.vue')},
  {key: 'catalog-product-bundle-select', concrete: () => import('./components/catalog/product/bundle/type/BundleSelect.vue')},
  {key: 'catalog-product-bundle-radio', concrete: () => import('./components/catalog/product/bundle/type/BundleRadio.vue')},
  {key: 'catalog-product-bundle-checkbox', concrete: () => import('./components/catalog/product/bundle/type/BundleCheckbox.vue')},
  {key: 'catalog-product-grouped', concrete: () => import('./components/catalog/product/grouped/ProductTypeGrouped.vue')},
  {key: 'catalog-product-grouped-product', concrete: () => import('./components/catalog/product/grouped/ProductTypeGroupedProduct.vue')},

  {key: 'catalog-product-upsell-off-canvas', concrete: () => import('./components/catalog/product/ProductUpsellOffCanvas.vue')},

  {key: 'catalog-product-upsell', concrete: () => import('./components/catalog/product/ProductUpsell.vue')},
  {key: 'catalog-product-related', concrete: () => import('./components/catalog/product/ProductRelated.vue')},
  {key: 'catalog-product-series', concrete: () => import('./components/catalog/product/ProductSeries.vue')},
  {key: 'catalog-product-cross-sell', concrete: () => import('./components/catalog/product/ProductCrossSell.vue')},
  {key: 'catalog-product-family-products', concrete: () => import('./components/catalog/product/ProductFamilies.vue')},
  {key: 'catalog-product-recently-viewed', concrete: () => import('./components/catalog/product/ProductRecentlyViewed.vue')},

  {key: 'catalog-product-media', concrete: () => import('./components/catalog/product/ProductMedia.vue')},
  {key: 'catalog-product-media-full-screen', concrete: () => import('./components/catalog/product/ProductMediaFullScreen.vue')},

  {key: 'product-slider', concrete: () => import('./components/catalog/ProductSlider.vue')},

  //Customer
  {key: 'customer-wishlist', concrete: () => import('./components/customer/CustomerWishlist.vue')},
  {key: 'customer-address-autocomplete', concrete: () => import('./components/customer/AddressAutocomplete.vue')},

  //Checkout
  {key: 'checkout-checkout', concrete: () => import('./components/checkout/Checkout.vue')},

  {key: 'checkout-address-detail', concrete: () => import('./components/checkout/step/AddressDetail.vue')},
  {key: 'checkout-address-shipping', concrete: () => import('./components/checkout/address/ShippingAddress.vue')},
  {key: 'checkout-address-billing', concrete: () => import('./components/checkout/address/BillingAddress.vue')},
  {key: 'checkout-address-login', concrete: () => import('./components/checkout/address/CheckoutLogin.vue')},
  {key: 'checkout-address-autocomplete', concrete: () => import('./components/checkout/address/input/AddressAutocomplete.vue')},
  {key: 'checkout-address-form', concrete: () => import('./components/checkout/address/input/AddressForm.vue')},
  {key: 'checkout-address-company-input', concrete: () => import('./components/checkout/address/input/AddressCompanyInput.vue')},
  {key: 'checkout-address-select', concrete: () => import('./components/checkout/address/input/AddressSelect.vue')},
  {key: 'checkout-address-option', concrete: () => import('./components/checkout/address/input/AddressOption.vue')},
  {key: 'checkout-create-account-password', concrete: () => import('./components/checkout/address/CreateAccountPassword.vue')},

  {key: 'checkout-shipping-detail', concrete: () => import('./components/checkout/step/ShippingDetail.vue')},
  {key: 'checkout-shipping-methods-default', concrete: () => import('./components/checkout/shipping/methods/Default.vue')},
  {key: 'checkout-shipping-methods-component-tablerate', concrete: () => import('./components/checkout/shipping/methods/components/Tablerate.vue')},
  {key: 'checkout-shipping-methods-pickup', concrete: () => import('./components/checkout/shipping/methods/Pickup.vue')},
  {key: 'checkout-shipping-method-postnl-pickup-points', concrete: () => import('./components/checkout/shipping/methods/postnl/PickupPoints.vue')},

  {key: 'checkout-progress-bar', concrete: () => import('./components/checkout/step/ProgressBar.vue')},
  {key: 'checkout-payment-detail', concrete: () => import('./components/checkout/step/PaymentDetail.vue')},
  {key: 'checkout-payment-methods-default', concrete: () => import('./components/checkout/payment/methods/Default.vue')},
  {key: 'checkout-payment-methods-ideal', concrete: () => import('./components/checkout/payment/methods/Ideal.vue')},
  {key: 'checkout-payment-methods-creditcard', concrete: () => import('./components/checkout/payment/methods/CreditCard.vue')},
  {key: 'checkout-payment-methods-afterpay', concrete: () => import('./components/checkout/payment/methods/Afterpay.vue')},
  {key: 'checkout-payment-methods-bank-transfer', concrete: () => import('./components/checkout/payment/methods/BankTransfer.vue')},
  {key: 'opening-hours', concrete: () => import('./components/checkout/shipping/methods/components/OpeningHours.vue')},

  {key: 'checkout-summary', concrete: () => import('./components/checkout/summary/Summary.vue')},
  {key: 'checkout-summary-small', concrete: () => import('./components/checkout/summary/SummarySmall.vue')},
  {key: 'checkout-cart-totals', concrete: () => import('./components/checkout/summary/CartTotals.vue')},
  {key: 'checkout-coupon-form', concrete: () => import('./components/checkout/coupon/CouponForm.vue')},

  {key: 'register-account-widget', concrete: () => import('./components/checkout/success/RegisterAccountWidget.vue')},

  //Stores
  {key: 'store-search', concrete: () => import('./components/physical-stores/StoreSearch.vue')},
  {key: 'store-route', concrete: () => import('./components/physical-stores/StoreRoute.vue')},

  //Shop by looks
  {key: 'shop-by-look-series', concrete: () => import('./components/shop-by-look/ShopByLookSeries.vue')},
  {key: 'shop-by-look-image', concrete: () => import('./components/shop-by-look/ShopByLookImage.vue')},
  {key: 'shop-by-look-tile', concrete: () => import('./components/shop-by-look/ShopByLookTile.vue')},

  //Sliders and swipers
  {key: 'swiper', concrete: () => import('./shaked-exports/swiper/swiper')},
  {key: 'swiper-slide', concrete: () => import('./shaked-exports/swiper/swiper-slide')},
  {key: 'banner-slider', concrete: () => import('./components/blocks/bannerSlider.vue')},

  //Cms
  {key: 'product-select-widget', concrete: () => import('./components/cms/forms/ProductSelectWidget.vue')},

  //Lazy load components
  {key: 'lazy-load', concrete: () => import('./components/LazyLoad.vue')},

  //Kiosk
  {key: 'inactivity-checker', concrete: () => import('../../kiosk/js/components/ui/inactivityChecker.vue')},
]);

import nl_NL from './i18n/nl_NL';

import {localize} from 'vee-validate';
import vee_en_GB from 'vee-validate/dist/locale/en.json';
import vee_nl_NL from 'vee-validate/dist/locale/nl.json';
import vee_de_DE from 'vee-validate/dist/locale/de.json';

import CheckoutTotals from "./stores/checkout/totals";
import CheckoutAddress from "./stores/checkout/address";
import CheckoutShipping from "./stores/checkout/shipping";
import CheckoutDatalayer from "./stores/checkout/datalayer";
import CheckoutGlobal from "./stores/checkout/global";
import CheckoutPayment from "./stores/checkout/payment";
import CheckoutQuote from "./stores/checkout/quote";
import CheckoutPostNLPickupPoints from "./stores/checkout/postnl/pickup-points";
import CheckoutPostNLTimeframes from "./stores/checkout/postnl/timeframes";
import CheckoutShippingPickupPoints from "./stores/checkout/pickup";

import CustomerAddress from "./stores/customer/address";

localize({vee_en_GB, vee_nl_NL, vee_de_DE});
localize(`vee_${window.Locale === 'nl_BE' ? 'nl_NL' : window.Locale}`);

const messages = {
  nl_NL
};

const i18n = new VueI18n({
  locale: window.Locale,
  fallbackLocale: 'nl_NL',
  formatFallbackMessages: true,
  silentTranslationWarn: true,
  messages
});

new Vue({
  store,
  i18n,
  components: ServiceContainer().getComponents(),
  data: {
    csrfToken: window.csrfToken
  },

  async created() {
    this.$eventBus.$on('item-rendered', () => {
      const catalogCategoryProductPlaceholders = document.querySelector('.catalog-category__list--placeholder');
      if (catalogCategoryProductPlaceholders) {
        (catalogCategoryProductPlaceholders as HTMLElement).style.display = 'none';
      }
    });
  }
}).$mount('#solar-app');

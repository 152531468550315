import Vue from 'vue';
import Vuex from 'vuex';
import MobileDetect from 'mobile-detect';
import {HttpClientFactory} from '@flashpointbv/solar-ui-http-client';
import Environment from '@flashpointbv/solar-ui-environment';
import {AxiosInstance} from 'axios';
import {appSessionStorage} from './shared/services';
import {EnvironmentContainer} from '@flashpointbv/solar-ui-environment/dist/EnvironmentContainer';
import {UICoreCookies} from "@flashpointbv/solar-ui-core";

declare module 'vue/types/vue' {
  interface Vue {
    $mobileDetect: MobileDetect
    $mageClient: AxiosInstance,
    $solarClient: AxiosInstance,
    $eventBus: Vue,
    $config: EnvironmentContainer
  }
}

declare module 'vuex/types' {
  interface Store<S> {
    $mageClient: AxiosInstance,
    $solarClient: AxiosInstance,
    $config: EnvironmentContainer
  }
}

const convertPrice = (value: number, locale?: string): string => {
  switch (locale) {
    case 'de_DE': return value.toFixed(2).replace('.', ',');
    case 'en_GB': return value.toFixed(2).replace(',00', ',-');
    default: return value.toFixed(2).replace('.', ',').replace(',00', ',-');
  }
};

/**
 * Price format prototype
 */
Number.prototype['priceFormat'] = function(locale = null): string {
  return convertPrice(this, locale);
};

/**
 * Price format prototype
 */
String.prototype['priceFormat'] = function(locale = null): string {
  return convertPrice(parseFloat(this), locale);
};

/**
 * Stripslashes prototype
 */
String.prototype['stripslashes'] = function(): string {
  return this.replace(/\\(.)/mg, "$1");
};

const mageClient = HttpClientFactory.makeInstance({
  baseURL: Environment().get('mage_api_base_url'),
  headers: {
    common: {}
  },
  withCredentials: true
});

const solarClient = HttpClientFactory.makeInstance({
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-XSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')['content'] || '',
      'X-STORE-ID': Environment().get('active_store_id')
    },
  },
  withCredentials: true
});

solarClient.interceptors.request.use(
  (config) => {
    const token = new UICoreCookies().getCookie('XSRF-TOKEN')
    if (token) {
      config.headers['X-XSRF-TOKEN'] = token
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  }
);

// Vue prototype injections
Vue.prototype.$mobileDetect = new MobileDetect(window.navigator.userAgent);
Vue.prototype.$eventBus = new Vue;
Vue.prototype.$mageClient = mageClient;
Vue.prototype.$solarClient = solarClient;
Vue.prototype.$config = Environment();

// Vuex prototype injections
Vuex.Store.prototype['$mageClient'] = mageClient;
Vuex.Store.prototype['$solarClient'] = solarClient;
Vuex.Store.prototype['$config'] = Environment();
